import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('../layouts/Main.vue'),
    children: [
      {
        path: '',
        name: 'home',
        component: () => import('../views/Home'),
        meta: { 
          title: 'Softsim Technologies Inc. | IT and Business Consulting',
          breadcrumb:[
            {text:'home',href:'/',disabled:true},
          ]
        }
      },
      {
        path: '/contact',
        name: 'contact',
        component: () => import('../views/Contact'),
        meta: { 
          title: 'Contact Us | IT and Business Consulting',
          breadcrumb:[
            {text:'contact',href:'/contact',disabled:true},
          ]
        }
      },
      {
        path: '/about',
        name: 'about',
        component: () => import('../views/About'),
        meta: { 
          title: 'About Us | IT and Business Consulting',
          breadcrumb:[
            {text:'about',href:'/about',disabled:true},
          ]
        }
      },
      {
        path: '/solutions',
        name: 'solutions',
        component: () => import('../views/Solutions/Solutions'),
        meta: { 
          title: 'Solutions | IT and Business Consulting',
          breadcrumb:[
            {text:'solutions',href:'/solutions',disabled:true},
          ]
        }
      },
      {
        path: '/solutions/innovations',
        name: 'innovations',
        component: () => import('../views/Solutions/Innovations'),
        meta: { 
          title: 'Innovations | IT and Business Consulting',
          breadcrumb:[
            {text:'solutions',href:'/solutions',disabled:false},
            {text:'innovations',href:'/solutions/innovations',disabled:true},
          ]
        }
      },
      {
        path: '/solutions/services',
        name: 'services',
        component: () => import('../views/Solutions/Services'),
        meta: { 
          title: 'Services | IT and Business Consulting',
          breadcrumb:[
            {text:'solutions',href:'/solutions',disabled:false},
            {text:'services',href:'/solutions/services',disabled:true},
          ]
        }
      },
      {
        path: '/solutions/products/:productid?',
        name: 'products',
        component: () => import('../views/Solutions/Products'),
        meta: { 
          title: 'Products | IT and Business Consulting',
          breadcrumb:[
            {text:'solutions',href:'/solutions',disabled:false},
            {text:'products',href:'/solutions/procucts',disabled:true},
          ]
        }
      },
      {
        path: '/clients-partners/clients',
        name: 'clients',
        component: () => import('../views/Clients & Partners/Clients'),
        meta: { 
          title: 'Clients | IT and Business Consulting',
          breadcrumb:[
            {text:'clients',href:'/clients-partners/clients',disabled:true},
          ]
        }
      },
      {
        path: '/clients-partners/partners',
        name: 'partners',
        component: () => import('../views/Clients & Partners/Partners'),
        meta: { 
          title: 'Partners | IT and Business Consulting',
          breadcrumb:[
            {text:'partners',href:'/clients-partners/partners',disabled:true},
          ]
        }
      },
      {
        path: '/blog',
        name: 'blog',
        component: () => import('../views/Blog/Blog'),
        meta: { 
          title: 'Blog | IT and Business Consulting',
          breadcrumb:[
            {text:'blog',href:'/blog',disabled:true},
          ]
        }
      },
      {
        path: '/blog/news-events/:search?/:blogid?',
        name: 'newsevents',
        component: () => import('../views/Blog/NewsEvents'),
        meta: { 
          title: 'News & Events | IT and Business Consulting',
          breadcrumb:[
            {text:'blog',href:'/blog',disabled:false},
            {text:'newsevents',href:'/blog/news-events',disabled:true},
          ]
        }
      },
      {
        path: '/blog/gallery',
        name: 'gallery',
        component: () => import('../views/Blog/Gallery'),
        meta: { 
          title: 'Gallery | IT and Business Consulting',
          breadcrumb:[
            {text:'blog',href:'/blog',disabled:false},
            {text:'gallery',href:'/blog/gallery',disabled:true},
          ]
        }
      },
      {
        path: '/recruitment',
        name: 'recruitment',
        component: () => import('../views/Recruitment/Recruitment'),
        meta: { 
          title: 'Recruitment | IT and Business Consulting',
          breadcrumb:[
            {text:'recruitment',href:'/recruitment',disabled:true},
          ]
        }
      },
      {
        path: '/recruitment/job-seeker',
        name: 'jobseeker',
        component: () => import('../views/Recruitment/JobSeeker'),
        meta: { 
          title: 'Job Seeker | IT and Business Consulting',
          breadcrumb:[
            {text:'recruitment',href:'/recruitment',disabled:false},
            {text:'jobseeker',href:'/recruitment/job-seeker',disabled:true},
          ]
        }
      },
      {
        path: '/recruitment/employer',
        name: 'employer',
        component: () => import('../views/Recruitment/Employer'),
        meta: { 
          title: 'Employer | IT and Business Consulting',
          breadcrumb:[
            {text:'recruitment',href:'/recruitment',disabled:false},
            {text:'employer',href:'/recruitment/employer',disabled:true},
          ]
        }
      },
      {
        path: '/login',
        name: 'login',
        component: () => import('../views/Admin/Login'),
        meta: { 
          title: 'Login | IT and Business Consulting',
          breadcrumb:[
            {text:'home',href:'/',disabled:false},
            {text:'login',href:'/login',disabled:true},
          ]
        }
      },
      {
        path: '/profile',
        name: 'profile',
        component: () => import('../views/Admin/Profile'),
        meta: { 
          title: 'Profile | IT and Business Consulting',
          requiresAuth: true,
          breadcrumb:[
            {text:'home',href:'/',disabled:false},
            {text:'profile',href:'/profile',disabled:true},
          ]
        }
      },
      {
        path: '/inbox',
        name: 'inbox',
        component: () => import('../views/Admin/Inbox'),
        meta: { 
          title: 'Inbox | IT and Business Consulting',
          requiresAuth: true,
          breadcrumb:[
            {text:'home',href:'/',disabled:false},
            {text:'inbox',href:'/inbox',disabled:true},
          ]
        }
      },
      {
        path: '/roles-permissions',
        name: 'rolespermissions',
        component: () => import('../views/Admin/RolesPermissions'),
        meta: { 
          title: 'Roles & Permissions | IT and Business Consulting',
          requiresAuth: true,
          breadcrumb:[
            {text:'home',href:'/',disabled:false},
            {text:'rolespermissions',href:'/roles-permissions',disabled:true},
          ]
        }
      },
    ]
  },
  {
    name: 'error',
    path: '*',
    component: () => import('../views/ErrorPage'),
    meta: { title: 'Page Not Found | IT and Business Consulting' }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});



export default router;
