import softsimApi from "@/plugins/softsimApi";

const state = {
    loading: false,
    contacts:[],
}


const getters = {
    getContacts(state) {
        var result = [...state.contacts];
        return result;
    }
}

const actions = {

    addContact(context, payload) {
        context.commit('setLoading',true);
        return softsimApi.postRequest("/contact", payload).then(response => {
            context.commit('addContact',response.data);
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    updateContact(context, payload) {
        context.commit('setLoading',true);
        return softsimApi.patchRequest("/contact/"+payload.id, payload).then(response=>{
            context.commit('updateContact', response.data);
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    deleteContact(context, payload ){
        context.commit('setLoading',true);
        return softsimApi.deleteRequest( `/contact/`+ payload.id).then(response=>{
            context.commit('deleteContact', response.data );
            return response;
        }).finally(() => {
            context.commit('setLoading',false);
        });
    },
    retrieveContacts(context) {
        context.commit('setLoading',true);
        return softsimApi.getRequest('/contact' ).then(response => {
            context.commit('retrieveContacts', response.data);
            context.commit('setLoading',false);
        });
    },

} 


const mutations = {
    setLoading(state,payload){
        state.loading=payload;
    },
    addContact(state,payload){
        state.contacts.unshift(payload);
    },
    updateContact(state,payload){
        const edited_item_index=state.contacts.findIndex(
            (item) => item.id === payload.id
            );
        if (edited_item_index !== -1)
            state.contacts[edited_item_index] = payload;
    },
    deleteContact(state,payload){
        state.contacts = state.contacts.filter((contact)=>{
            return contact.id!=payload.id;
        });
    },
    retrieveContacts(state,payload){
        state.contacts=payload.data;
    },

}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
